import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import React from "react";
import { Service, ServiceTags } from "../models/Service";

function ListItemLink(props: any) {
  return <ListItem button component="a" {...props} />;
}

interface Props {
  service: Service;
  onOpenServiceOwner(): void;
}
const numericRegex = /^[0-9]+$/;
const datadogDashboardBase = "https://app.datadoghq.com";
const infinityDatadogDashboardId = "1217768";
const serviceCatalogBase = "https://catalog.cloud.scout24.com/service";
const datadogTraceBaseURL =
  "https://app.datadoghq.com/apm/traces?query=service%3A";
const datadogServiceMapBaseURL =
  "https://app.datadoghq.com/apm/map/?query=service%3A";

const getPropertyFromTags = (property: string, tags?: ServiceTags) => {
  if (!tags || !property) {
    return null;
  }
  if (tags.hasOwnProperty(property)) {
    return tags[property];
  }
  return null;
};

const ServiceActions = ({ service, onOpenServiceOwner }: Props) => {
  const engine = "kubernetes";
  const datadogDashboardId =
    service.service.datadogDashboardId || infinityDatadogDashboardId;
  const datadogDashboardPrefix = `${datadogDashboardBase}${
    numericRegex.test(datadogDashboardId) ? "/dash" : "/dashboard"
  }`;
  const metricsDashboardUrl = `${datadogDashboardPrefix}/${datadogDashboardId}?tpl_var_service-name=${
    service.name
  }${
    service.service.ecsServiceName
      ? `&tpl_var_ecs-service-name=${service.service.ecsServiceName.toLowerCase()}`
      : ""
  }`;

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(
    undefined
  );

  let serviceCatalogUrl = null;

  const componentIdTag = getPropertyFromTags("component-id", service.tags);
  const serviceIdTag = getPropertyFromTags("service-id", service.tags);
  const stageTag = getPropertyFromTags("stage", service.tags);

  let tracingUrl = null;
  let serviceMapUrl = null;
  if (serviceIdTag) {
    serviceCatalogUrl = `${serviceCatalogBase}/${serviceIdTag}`;
  }

  // special step to convert component-id tag from Scout24/component-id to Scout24_component-id format
  let componentIdForDatadog = serviceIdTag; // default to serviceIdTag
  if (componentIdTag) {
    componentIdForDatadog = componentIdTag.replace(/(S|s)cout24\//, "scout24_");
  }
  if (serviceIdTag && stageTag) {
    tracingUrl = `${datadogTraceBaseURL}${componentIdForDatadog}+env:${stageTag}`;
    serviceMapUrl = `${datadogServiceMapBaseURL}${componentIdForDatadog}&env=${stageTag}`;
  }
  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(undefined);
  }

  return (
    <div>
      <IconButton
        aria-owns={anchorEl ? "service-actions" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu
        id="service-actions"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ListItemLink
          onClick={handleClose}
          target="_blank"
          href={
            "https://logging.cloud.scout24.com/app/kibana_hash/discover?_g=(filters:!())&_a=(columns:!(message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,key:service_name,negate:!f,params:(query:" +
            service.name +
            "),type:phrase,value:" +
            service.name +
            "),query:(match:(service_name:(query:" +
            service.name +
            ",type:phrase))))),interval:auto,query:(language:kuery,query:''),sort:!(!(timestamp,desc)))"
          }
        >
          <ListItemIcon>
            <Icon>format_align_left_icon</Icon>
          </ListItemIcon>
          <ListItemText>Logs</ListItemText>
        </ListItemLink>
        <ListItemLink
          onClick={handleClose}
          target="_blank"
          href={metricsDashboardUrl}
        >
          <ListItemIcon>
            <Icon>insert_chart</Icon>
          </ListItemIcon>
          <ListItemText>Metrics</ListItemText>
        </ListItemLink>
        <ListItemLink
          onClick={handleClose}
          target="_blank"
          href={
            "https://app.datadoghq.com/event/stream?query=source:" +
            engine.replace("ecs", "docker,ecs") +
            " " +
            service.name
          }
        >
          <ListItemIcon>
            <Icon>insert_chart</Icon>
          </ListItemIcon>
          <ListItemText>Lifecycle events</ListItemText>
        </ListItemLink>
        {service.service.status.dns !== undefined ? (
          <ListItemLink
            onClick={handleClose}
            target="_blank"
            href={"https://" + service.service.status.dns}
          >
            <ListItemIcon>
              <Icon>web</Icon>
            </ListItemIcon>
            <ListItemText>Service</ListItemText>
          </ListItemLink>
        ) : null}
        <ListItemLink onClick={onOpenServiceOwner}>
          <ListItemIcon>
            <Icon>info</Icon>
          </ListItemIcon>
          <ListItemText>Service Information</ListItemText>
        </ListItemLink>
        <ListItemLink
          onClick={handleClose}
          disabled={!serviceCatalogUrl}
          target="_blank"
          href={serviceCatalogUrl}
        >
          <ListItemIcon>
            <Icon>info_outlined</Icon>
          </ListItemIcon>
          <ListItemText>Service Catalog</ListItemText>
        </ListItemLink>
        <ListItemLink
          onClick={handleClose}
          disabled={!tracingUrl}
          target="_blank"
          href={tracingUrl}
        >
          <ListItemIcon>
            <Icon>timeline</Icon>
          </ListItemIcon>
          <ListItemText>Traces</ListItemText>
        </ListItemLink>
        <ListItemLink
          onClick={handleClose}
          disabled={!serviceMapUrl}
          target="_blank"
          href={serviceMapUrl}
        >
          <ListItemIcon>
            <Icon>map</Icon>
          </ListItemIcon>
          <ListItemText>Service Map</ListItemText>
        </ListItemLink>
      </Menu>
    </div>
  );
};

export default ServiceActions;
